import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://acepunt.kushubmedia.com/user/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});


export default {
  verfiyNumber(params) {
    return apiClient.post('verifyNumber', params);
  },

  register(params) {
    return apiClient.post('register', params);
  },

  verifyOtp(params) {
    return apiClient.post('verifyOtp', params);
  },

  login(params) {
    return apiClient.post('login', params);
  },

  getloginViaToken(token) {
    return apiClient.get(`getUserByToken/${token}`);
  },

  loginViaOtp(params) {
    return apiClient.post('loginOtp', params);
  },

  forgetPassword(params) {
    return apiClient.post(`forgotPassword`, params);
  },

  updatePassword(params) {
    return apiClient.post('updatePassword', params);
  },

  authUserCred(params) {
    return axios.post('https://wapi.paisaexch.com/api/verifytoken', params);
  },
};
