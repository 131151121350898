import authModule from '../../Services/auth.service.js';
// import { Storage } from '@ionic/storage';
// const storage = new Storage();
// const createStorage = async () => {
//   await storage.create();
// };
// createStorage();

const state = () => ({
  verifyOtpData: [],
  registerData: [],
  OtpData: [],
  loginData: [],
  loading: false,
  loginViaOtp: {},
  loginOtpStatus: {},
  loginTokenStatus: {},
  forgotPassword: {},
  authCred: {},
});

const mutations = {
  SET_VERIFY_USER(state, payload) {
    state.verifyOtpData = payload;
    // console.log('verify', state.verifyOtpData);
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_REGISTER(state, payload) {
    state.registerData = payload;
  },

  SET_LOGIN(state, payload) {
    state.loginData = payload;
    console.log('token1111', payload);
    console.log('token2222', payload.data.token);
    console.log('token3333', payload.data.doc._id);
    localStorage.setItem('token', payload.data.token);
    localStorage.setItem('userId', payload.data.doc._id);
   
  },

  SET_OTP(state, payload) {
    state.OtpData = payload;
  },

  SET_LOGIN_OTP(state, payload) {
    state.loginViaOtp = payload;
  },

  SET_LOGIN_OTP_STATUS(state, data) {
    state.loginOtpStatus = data;
  },

  SET_LOGIN_VIA_TOKEN(state, data) {
    state.loginTokenStatus = data;
  },

  SET_FORGOT_PASSWORD(state, data) {
    state.forgotPassword = data;
  },

  SET_UPDATE_PASSWORD(state, data) {
    state.updatePassword = data;
  },

  SET_AUTH_CRED(state, data) {
    state.authCred = data;
  },
};

const actions = {
  async verifyUser({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.verfiyNumber(payload);
      commit('SET_VERIFY_USER', res.data);
      commit('SET_LOADING', false);
      console.log('typeid',res.data.typeId)
    } catch (err) {
      console.error('error in verify user api :', err);
    }
  },

  async getUser({ commit }, payload) {
    commit('SET_LOADING', true);
    // console.log("payload",payload);
    try {
      const res = await authModule.getloginViaToken(payload);
      console.log("get data", res.data);
      commit('SET_LOGIN_VIA_TOKEN', res.data);
      commit('SET_LOGIN', res.data);
      commit('SET_LOADING', false);
    } catch (err) {
      console.error('error in verify user api :', err);
    }
  },


  async registerUser({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.register(payload);
      commit('SET_REGISTER', res.data);
      commit('SET_LOADING', false);
    } catch (err) {
      console.error('error in register user api :', err);
    }
  },

  async otpSubmit({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.verifyOtp(payload);
      commit('SET_OTP', res.data);
      commit('SET_LOADING', false);
    } catch (err) {
      console.error('error in otpSubmit api :', err);
    }
  },

  async getOtpForLogin({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.loginViaOtp(payload);
      commit('SET_LOGIN_OTP', res.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getOtpForLogin api :', error);
    }
  },

  async getVerifyOtpLogin({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.loginViaOtp(payload);
      commit('SET_LOGIN_OTP_STATUS', res.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getVerifyOtpLogin api :', error);
    }
  },

  async forgotPassword({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.forgetPassword(payload);
      commit('SET_FORGOT_PASSWORD', res.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in forgotPassword api :', error);
    }
  },

  async updatePassword({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.updatePassword(payload);
      commit('SET_UPDATE_PASSWORD', res.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in updatePassword api :', error);
    }
  },

  async getPaisaExchUrl({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const res = await authModule.authUserCred(payload);
      commit('SET_AUTH_CRED', res.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in authUserCred api :', error);
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
