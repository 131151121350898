<template>
  <ion-page >
    <ion-content >
      <!-- <ion-loading :is-open="userLoading" cssClass="my-custom-class"></ion-loading> -->
      <ion-row style="text-align:center">
        <ion-col 
          size="3.3"
          v-for="item in allSites.doc"
          :key="item.id"
          class="maincol"
        >
          <ion-col @click="open = true" style="justify-content: center; display: flex">
            <ion-avatar>
              <ion-img
                :src="`https://acepunt.kushubmedia.com/uploads/sites/${item.image}`"
              ></ion-img>
            </ion-avatar>
          </ion-col >
          <b  @click="open = true" style="font-size: 65%">{{ item.name.toUpperCase() }}</b
          ><br />
          <b style="font-size: 45%">{{ item.url }}</b> <br />

          <ion-button @click="createId(item)" class="createidbtn" expand="block" fill="blank">
            CREATE ID
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-content>
      <!-- modal -->

      <ion-modal 
        :is-open="open"
        style= "height:41%; width: 80%; margin: 10em 0em 0em 2.6em; color: white; border-radius:16px;"
      >
      
        
        <ion-row >

            <ion-col @click="open = false" size="12" style="display:flex;justify-content:right;background: linear-gradient(251deg, #40d4cc, black) !important;">
                <ion-icon @click="open = false" style="font-size:31px;margin:5px 10px;color: red;"
                :md="close">
            </ion-icon>
            </ion-col>
        </ion-row>
        <ion-content>
          <ion-row style=" background: linear-gradient(634deg, #40d4cc, black) !important;" v-for="data in allSites.doc" :key="data.id" >
           
            <ion-col size="12" style="text-align: center;margin:6px 0px 20px 0px;padding: 15px;border-bottom: 2px solid;"><b>{{data.name}}</b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>DemoId</b> </ion-col>
           <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.demoId}} </b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>Password</b> </ion-col>
            <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.demoPassword}}</b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>Cricket</b></ion-col>
            <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.cricket}}</b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>Football</b></ion-col>
            <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.football}}</b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>Tennis</b></ion-col>
            <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.tennis}}</b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>Horse Racing</b></ion-col>
            <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.horse_racing}}</b></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5" style="text-align:start"><b>Cards</b></ion-col>
            <ion-col size="1" style="text-align:center"> - </ion-col>
            <ion-col size="5" style="text-align:center"><b>{{data.cards}}</b></ion-col>
            <ion-col size="1" style="border-bottom: 2px solid;"></ion-col>
            <ion-col size="5" style="text-align:start;border-bottom: 2px solid;"><b>Live Casino</b></ion-col>
            <ion-col size="1" style="text-align:center;border-bottom: 2px solid;"> - </ion-col>
            <ion-col size="5" style="text-align:center;border-bottom: 2px solid;"><b>{{data.live_casino}}</b></ion-col>
          </ion-row>
         
        
    
    </ion-content>
      </ion-modal>
    
   
  </ion-page>
</template>
<script langh="ts">
import { Clipboard } from "@capacitor/clipboard";
import { defineComponent, ref, inject, computed } from "vue";
import { star } from "ionicons/icons";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { close,closeOutline} from "ionicons/icons";
import CreatIDPage from "@/views/CreateIdScreen.vue";
import {
  IonPage,
  IonContent,
  IonRow,
  IonCol,
  IonAvatar,
  IonImg,
  IonModal,
  IonIcon,
  //IonLoading
  
} from "@ionic/vue";

export default defineComponent({
  name: "CreateIdNew",
  components: {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonAvatar,
    IonImg,
    IonModal,
    IonIcon,
   // IonLoading
  },
  data() {
    return {
      open: false,
      //userLoading: true
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const allSites = computed(() => store.state.userStore.allSites);
    
    
    const fetchAllSite = () => {
      let obj = {
        type: inject("type"),
        typeId: inject("typeId"),
      };
      store.dispatch("userStore/getAllSites", obj);
      
    };
    fetchAllSite();
     
    
    const createId = (site)=>{
      router.push({
        name: "createIdPage",
        params:{id:site._id}
    });
    };
    return {
      allSites,
      close,
      closeOutline,
      createId,
      CreatIDPage,
     
    };
  },
 
    
  
 
 
 
  
});
</script>
<style>
.maincol {
  text-align: center;
  background: linear-gradient(693deg, #000000, #42525e);
  justify-content: center;
   margin: 4.99% 1% 1.99% 4.2%;
  border-radius: 12px;
  height: 100%;
}
.createidbtn {
  font-size: 13px;
  letter-spacing: 1px;
  margin: 10px -4px -2px -4px;
  --background: #5ede5e;
  --border-radius: 0px 0px 12px 12px;
}
</style>