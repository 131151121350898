import userModule from '../../Services/user.service';

const state = () => ({
  userLoading: false,
  walletBalance: '',
  manualPaymentList: [],
  depositPaymentData: [],
  userDetail: {},
  getWithdrawal: [],
  allSites: [],
  site: [],
  setPrefferdWithdrawn: [],
  transactions: [],
  createSiteData: [],
  transactionDetail: [],
  withdrawalStatus: [],
  notification: [],
  statusCode: '',
  mySites: [],
  pendingTransaction: [],
  depositeMyId: [],
  withdrawalMyId: [],
  myIDData: [],
  myIdTransDetails: {},
  userBalance: [],
  deleteWithdrawal: {},
  cancelWithdrawal: {},
  typeId:'',
  newresp:'',
  // managerName:'',
});

const mutations = {
 
  SET_STATUSCODE_ERROR(state, data) {
    state.statusCode = data;
    console.error('error', data);
  },
  SET_USER_DETAILS(state, data) {
    state.userDetail = data;
  },
  // SET_MANAGER_NAME(state,payload){
  //   state.managerName =payload;
  // },

  SET_LOADING(state, payload) {
    state.userLoading = payload;
  },

  SET_MANUAL_PAYMENT(state, payload) {
    state.manualPaymentList = payload;
  },

  SET_DEPOSIT_PAYMENT(state, payload) {
    state.depositPaymentData = payload;
  },

  SET_SEND_WITHDRAWAL(state, data) {
    state.sendWithdrawalDetail = data;
  },

  SET_GET_WITHDRAWAL(state, data) {
    state.getWithdrawal = data;
  },

  SET_SITES_DATA(state, data) {
    state.allSites = data;
    console.log('allsites', data);
  },

  SET_SITE_DATA(state, data) {
    state.site = data;
    console.log('site', data);
  },

  SET_PREFFERD_WITHDRAWN(state, data) {
    state.setPrefferdWithdrawn = data;
  },

  SET_TRANSACTION(state, data) {
    state.transactions = data;
    console.log('transaction', state.transactions);
  },

  SET_CREATE_SITE(state, data) {
    state.createSiteData = data;
  },

  SET_TRANS_DETAILS(state, data) {
    state.transactionDetail = data;
    console.log('trans_detail', data);
  },

  SET_WITHDRAWAN_SUCCESS_DATA(state, data) {
    state.withdrawalStatus = data;
  },

  SET_NOTIFICATION(state, data) {
    state.notification = data;
  },
  

  SET_MY_SITES(state, data) {
    state.mySites = data;
    console.log('mysites', data);
  },

  SET_PENDING_TRANS(state, data) {
    state.pendingTransaction = data;
    console.log('pendingTrans', data);
  },

  SET_DEPOSIT_MYID(state, data) {
    state.depositeMyId = data;
    console.log('depositeMyId', data);
  },

  SET_WITHDRAWAL_MYID(state, data) {
    state.withdrawalMyId = data;
    console.log('withdrawalMyId', data);
  },

  SET_MYSITE_DATA(state, data) {
    state.myIDData = data;
    console.log('MyId', data);
  },

  SET_MYID_TRANS_DETAILS(state, data) {
    state.myIdTransDetails = data;
    console.log('data', data);
  },

  SET_USER_BALANCE(state, data) {
    state.userBalance = data;
    console.log('balance', data);
  },

  SET_DELETE_WITHDRAWAL(state, data) {
    state.deleteWithdrawal = data;
  },

  SET_CANCEL_WITHDRAWAL(state, data) {
    state.cancelWithdrawal = data;
  },
  SET_TYPEID (state,data){
    state.typeId = data;
  },
  SET_NEWRESP(state,data){
    state.newresp = data;
  },
 
  
};

const actions = {
  async getUserDetails({ commit }) {
    try {
      const response = await userModule.getUserDetail(
        localStorage.getItem('token'),
      
      );
      console.log('res',response)
      commit('SET_USER_DETAILS', response.data);
      commit('SET_TYPEID',response.data.doc.typeId)
      
    } catch (error) {
      console.error('error in getUser api: ', error);
      commit('SET_LOADING', false);
    }
  },
 
 

  async getManualPayment({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getManualPayment(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_MANUAL_PAYMENT', response.data);
      
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getPaymentMethod: ', error);
      commit('SET_LOADING', false);
    }
  },
  // async getManager({commit},payload){
  //   commit('SET_LOADING', true);
  //   try{
  //     const response = await userModule.getManager(
  //       payload,
  //       localStorage.getItem('token'),
        
  //     );
  //     commit('SET_MANAGER_NAME', response.data);
  //     console.log('m',response.data)
  //     commit('SET_LOADING', false);
  //   }catch(error){
  //     console.error('error in getManager: ', error);
  //     commit('SET_LOADING', false);
  //   }
  // },

  async depositPayment({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.depositPayment(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_DEPOSIT_PAYMENT', response.data);
      console.log('error',response.data)
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in depositPayment: ', error);
      commit('SET_STATUSCODE_ERROR', error);
      commit('SET_LOADING', false);
    }
  },

  async sendWithdrawalMethod({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.sendWithdrawalMethod(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_SEND_WITHDRAWAL', response.data);
      console.log('wd',response.data)
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in withdrawalMethod(send): ', error);
      commit('SET_LOADING', false);
    }
  },

  async getWithdrawalMethod({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getWithdrawalMethod(
        localStorage.getItem('token')
      );
      console.log('wm',response.data)
      commit('SET_GET_WITHDRAWAL', response.data);
    
     
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getWithdrawalMethod: ', error);
      commit('SET_LOADING', false);
    }
  },

  async withdrawalPayment({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await userModule.WithdrawalPayment(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_WITHDRAWAN_SUCCESS_DATA', response.data);
      console.log('new3',response.data.success)
    
      commit('SET_LOADING', false);
    
    } catch (error) {
      console.error('error in WithdrawalPayment: ', error);
      commit('SET_LOADING', false);
    }
  },


  async getAllSites({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getSites(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_SITES_DATA', response.data);
     
      commit('SET_LOADING', false);
     
    } catch (error) {
      console.error('error in getSites: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getSiteById({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getSiteById(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_SITE_DATA', response.data);
     
      commit('SET_LOADING', false);
     
    } catch (error) {
      console.error('error in getSitebyID: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getMySites({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getMySites(
        localStorage.getItem('token')
      );
      commit('SET_MY_SITES', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getMySites: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getPreferredWithdrawn({ commit }) {
    try {
      const response = await userModule.getPrefferedWithdrawl(
        localStorage.getItem('token')
      );
      commit('SET_PREFFERD_WITHDRAWN', response.data);
    } catch (error) {
      console.error('error in getPrefferdWithdrawn: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getTransactions({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getTransactions(
        localStorage.getItem('token')
      );
      commit('SET_TRANSACTION', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getTransactions: ', error);
      commit('SET_LOADING', false);
    }
  },

  async createSite({ commit }, payload) {
    commit('SET_LOADING', true);

    try {
      const response = await userModule.createSite(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_CREATE_SITE', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in create site: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getTransactionById({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getTransactionsById(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_TRANS_DETAILS', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in transaction by id: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getNotif({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getNotification(
        localStorage.getItem('token')
      );
      commit('SET_NOTIFICATION', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in notification : ', error);
      commit('SET_LOADING', false);
    }
  },

  async getUserPendingTransaction({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getUserPendingTransaction(
        localStorage.getItem('token')
      );
      commit('SET_PENDING_TRANS', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in pending transaction : ', error);
      commit('SET_LOADING', false);
    }
  },

  async getMySiteByID({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getMySitesById(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_MYSITE_DATA', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in my site by ID : ', error);
      commit('SET_LOADING', false);
    }
  },

  async depositMyID({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.depositMyID(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_DEPOSIT_MYID', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in deposit My Id : ', error);
      commit('SET_LOADING', false);
    }
  },

  async withdrawalMyID({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.withdrawalMyID(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_WITHDRAWAL_MYID', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in withdrawal My Id : ', error);
      commit('SET_LOADING', false);
    }
  },

  async getMyIdTransDetails({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await userModule.getMyIdTransaction(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_MYID_TRANS_DETAILS', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in getMySiteTransaction details: ', error);
      commit('SET_LOADING', false);
    }
  },

  async getUserBalance({ commit }, payload) {
    try {
      const response = await userModule.userBalance(payload);
      commit('SET_USER_BALANCE', response.data);
    } catch (error) {
      console.error('error in balance details: ', error);
      commit('SET_LOADING', false);
    }
  },

  async deleteWithdrawalDetail({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await userModule.deleteBankDetail(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_DELETE_WITHDRAWAL', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in delete details: ', error);
      commit('SET_LOADING', false);
    }
  },

  async cancleWithdrawal({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await userModule.cancelWithdrawal(
        payload,
        localStorage.getItem('token')
      );
      commit('SET_CANCEL_WITHDRAWAL', response.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('error in cancle withdrawal api: ', error);
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
