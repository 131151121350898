import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://acepunt.kushubmedia.com/user/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getUserDetail(token) {
    return apiClient.get(`getUser/${token}`);
  },

  // get manuall payment list..
  getManualPayment(params, token) {
    return apiClient.post(`getPaymentMethod/${token}`, params);
  },

  depositPayment(params, token) {
    return apiClient.post(`depositPayment/${token}`, params);
  },

  // send payment method...
  sendWithdrawalMethod(params, token) {
    return apiClient.post(`withdrawalMethod/${token}`, params);
  },

  WithdrawalPayment(params, token) {
    return apiClient.post(`withdrawalPayment/${token}`, params);
  },
 

  getWithdrawalMethod(token) {
    return apiClient.get(`getwithdrawnMethod/${token}`);
  },

  getPrefferedWithdrawl(token) {
    return apiClient.get(`getPrefferedWithdrawl/${token}`);
  },

  getSites(params, token) {
    return apiClient.post(`getSite/${token}`, params);
  },

  getSiteById(params, token) {
    return apiClient.post(`getsitesById/${token}`, params);
  },

  getMySites(token) {
    return apiClient.get(`getMysites/${token}`);
  },

  getTransactions(token) {
    return apiClient.get(`transactions/${token}`);
  },

  getTransactionsById(params, token) {
    return apiClient.post(`gettransactionById/${token}`, params);
  },

  createSite(params, token) {
    return apiClient.post(`createMysites/${token}`, params);
  },

  getNotification(token) {
    return apiClient.get(`getNotification/${token}`);
  },

  getUserPendingTransaction(token) {
    return apiClient.get(`pendingtransactions/${token}`);
  },

  depositMyID(params, token) {
    return apiClient.post(`depositInsite/${token}`, params);
  },

  withdrawalMyID(params, token) {
    return apiClient.post(`withdrawalInsites/${token}`, params);
  },

  getMySitesById(params, token) {
    return apiClient.post(`getMysitesByID/${token}`, params);
  },

  getMyIdTransaction(params, token) {
    return apiClient.post(`getmysiteTransaction/${token}`, params);
  },

  userBalance(username) {
    return apiClient.get(`https://wapi.paisaexch.com/api/balance/${username}`);
  },

  deleteBankDetail(params, token) {
    return apiClient.put(`deleteWithdrawlMethod/${token}`, params);
  },

  cancelWithdrawal(params, token) {
    return apiClient.put(`cancelWithdrawl/${token}`, params);
  },
  // getManager(params,token){
  //   return apiClient.post(`getManager/${token}`,params)
  // }
};
