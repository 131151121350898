import { createStore } from 'vuex';
import authStore from './Module/authModule';
import userStore from './Module/userModule';
export default createStore({
  modules: {
    authStore,
    userStore,
  },
  // * enable logging...
  // plugins: [createLogger()],
});
