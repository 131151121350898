import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import CreateIdNew from '../components/IdTabComponent/CreateIdNew.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/get-started',
  },

  {
    path: '/get-started',
    name: 'getStarted',
    props: true,
    component: () => import('../views/Authentication/GetStarted.vue'),
    beforeEnter: (to, from) => {
      if (localStorage.getItem('token')) {
        return '/tabs/home';
      } else false;
    },
  },

  {
    path: '/register',
    name: 'Register',
    props: true,
    component: () => import('../views/Authentication/RegisterScreen.vue'),
    beforeEnter: (to, from) => {
      if (localStorage.getItem('token')) {
        return '/tabs/home';
      } else false;
    },
  },

  {
    path: '/enter-otp',
    name: 'OtpScreen',
    props: true,
    component: () => import('../views/Authentication/OtpScreen.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    props: true,
    component: () => import('../views/Authentication/LoginScreen.vue'),
    beforeEnter: (to, from) => {
      if (localStorage.getItem('token')) {
        return '/tabs/home';
      } else false;
    },
  },

  {
    path: '/logintokenscreen/:token',
    name: 'LogintokenScreen',
    props: true,
    component: () => import('../views/Authentication/LogintokenScreen.vue'),
    // beforeEnter: (to, from) => {
    //   if (localStorage.getItem('token')) {
    //     return '/tabs/home';
    //   } else false;
    // },
  },

  {
    path: '/login-otp',
    name: 'LoginOtp',
    props: true,
    component: () => import('../views/Authentication/LoginOtp.vue'),
  },

  {
    path: '/forgot-password',
    name: 'forgotPassword',
    props: true,
    component: () => import('../views/Authentication/forgotPassword.vue'),
  },

  {
    path: '/update-password',
    name: 'updatePassword',
    props: true,
    component: () => import('../views/Authentication/updatePassword.vue'),
  },

  // app internal routes start....

  {
    path: '/tabs/',
    name: 'adminLayout',
    meta: {
      requireAuth: true, // same here
    },
    component: () => import('../layouts/adminLayout.vue'),
    children: [
      {
        path: '/tabs/',
        component: () => import('../views/IndexScreen.vue'),
        children: [
          {
            path: 'home',
            name: 'Home',
            props: true,

            component: () => import('../views/Tabs/homeTab.vue'),
          },
          {
            path: 'offers',
            name: 'Offer',
            props: true,

            component: () => import('../views/Tabs/offersTab.vue'),
          },
          {
            path: 'pass-book',
            name: 'Passbook',
            props: true,

            component: () => import('../views/Tabs/passbookTab.vue'),
          },
          {
            path: 'ids',
            name: 'IDs',
            props: true,

            component: () => import('../views/Tabs/idTab.vue'),
          },
          {
            path:'/new',
            name:'CreateIdNew',
            component: CreateIdNew
          }
        ],
      },
      {
        path: '/profile',
        name: 'Profile',
        props: true,

        component: () => import('../views/ProfileScreen.vue'),
      },
      {
        path: '/withdrawal-details',
        name: 'WithdrawalDetailPage',
        props: true,
        component: () => import('../views/WithdrawalDetailsScreen.vue'),
      },
      {
        path: '/deposit',
        name: 'Deposit',
        props: true,
        component: () => import('../views/DepositeScreen.vue'),
      },
      {
        path: '/withdrawal',
        name: 'Withdrawal',
        props: true,

        component: () => import('../views/WithdrawalScreen.vue'),
      },
      {
        path: '/refer',
        name: 'Refer',
        props: true,
        component: () => import('../views/Refer&EarnScreen.vue'),
      },
      {
        path: '/terms',
        name: 'Terms',
        props: true,
        component: () => import('../views/TermsScreen.vue'),
      },

      {
        path: '/payment-method',
        name: 'PaymentViaSS',
        props: true,
        component: () =>
          import('../views/Payment/PaymentViaScreenshotScreen.vue'),
      },

      {
        path: '/create-id',
        name: 'createIdPage',
        props: true,
        component: () => import('../views/CreateIdScreen.vue'),
      },
      {
        path: '/create-id1',
        name: 'createId',
        props: true,
        component: () => import('../../src/components/IdTabComponent/CreateId.vue'),
      },
      {
        path: '/transaction-detail',
        name: 'transactionDetail',
        props: true,
        component: () => import('../views/TransactionDetailScreen.vue'),
      },

      {
        path: '/notification',
        name: 'notificationPage',
        props: true,
        component: () => import('../views/NotificationList.vue'),
      },

      {
        path: '/myid-deposit',
        name: 'myIdDeposit',
        props: true,
        component: () => import('../views/MyID/MyIdDeposit.vue'),
      },

      {
        path: '/myid-withdrawal',
        name: 'myIdWithdrawal',
        props: true,
        component: () => import('../views/MyID/MyIdWithdrawal.vue'),
      },

      {
        path: 'my-id-detail',
        name: 'myIdTransDetail',
        props: true,
        component: () => import('../views/MyID/MyIdDetails.vue'),
      },
      {
        path: 'paisa-exch',
        name: 'paisaExchPage',
        props: true,
        component: () => import('../views/PaisaExchScreen.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token');
  const requirAuth = to.matched.some((record) => record.meta.requireAuth);

  if (requirAuth) {
    if (!loggedIn) {
      next('/');
      return;
    } else {
      next();
      return;
    }
  } else {
    next();
  }
});

export default router;
