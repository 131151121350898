import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
import OneSignal from 'onesignal-cordova-plugin';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import store from '@/store';

import { io } from 'socket.io-client';

const app = createApp(App).use(store).use(IonicVue).use(router);

// platform check...

// Call this function when your app starts
function OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE
  // OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId('9adf2973-6d8a-4a42-9ce0-a3a98d97deaa');
  OneSignal.setNotificationOpenedHandler(function (jsonData) {
    console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  });

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
    console.log('User accepted notifications: ' + accepted);
  });
}

document.addEventListener('deviceready', OneSignalInit, false);

// global variable
app.provide('type', 'Subadmin');
// app.provide('typeId', '624e7de05d117f479d1c70e0');    user id 6298db693453531745fc9c8f      manager -- 62f2104df5e891eb40dfb78d
app.provide('typeId', '6298db693453531745fc9c8f');


// socket globally...
app.provide('socketio', io(process.env.VUE_APP_SOCKET_ENDPOINT!));

// filters...

router.isReady().then(() => {
  app.mount('#app');
});
